import getConfig from 'next/config';
import { HireStatusOption } from '@/constants';
import { NextConfig } from '@/interfaces';

const { publicRuntimeConfig } = getConfig() as NextConfig;

const { AUTH_LOGIN_URL, CORE_BASE_URL } = publicRuntimeConfig;

export const BASE_URL = CORE_BASE_URL;

export const getQueryString = (params: { [key: string]: string | number | boolean }) => {
  const query = Object.keys(params)
    .map((k) => `${k}=${params[k]}`)
    .join('&');
  return query;
};

export const getEnrollmentUrl = (
  answer?: HireStatusOption,
  partnersRx?: string,
  partner?: string,
  skipTbf?: boolean
) => {
  const url = skipTbf
    ? `${CORE_BASE_URL}/app/hp-enrollment/landing`
    : `${CORE_BASE_URL}/app/hp-enrollment/signup`;
  const params = {} as { [key: string]: string };
  if (answer) {
    params.hired = answer.text === 'Yes' ? 'yes' : 'no';
  }
  if (partnersRx) {
    params.rx = partnersRx;
  }
  if (partner) {
    params.partner = partner;
    params.hired = 'yes';
  }
  const qs = getQueryString(params);
  return qs ? `${url}?${qs}` : url;
};

// should only be used on the client side
export const getLoginUrl = () =>
  `${AUTH_LOGIN_URL}?redirect_uri=${encodeURIComponent(window.location.href)}`;

export type URLParams = { [key: string]: string };

// workaround for czen using ? instead of & between query params
// replace all ? with & except the first one
export const fixUrlQuery = (queryString?: string) => {
  if (!queryString) return '';
  const arr = queryString.split('?');
  return `${arr[0]}?${arr.slice(1).join('&')}`;
};

export const getUrlQueryParamByName = (name: string, url?: string) => {
  if (!url) return '';
  const queryString = url.split('?')[1];
  if (!queryString) return '';
  const query = queryString.split('&');
  const params = new URLSearchParams();
  query.forEach((param) => {
    const [key, value] = param.split('=');
    params.set(key, value);
  });
  return params.get(name);
};
