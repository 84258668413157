import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Cookies from 'universal-cookie';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { AppContext, AppProps } from 'next/app';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { theme } from '@care/material-ui-theme';
import { JwtPayload } from '@care/fastify-authentication';
import * as Sentry from '@sentry/react';
import Script from 'next/script';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { AuthService, IAuthService, IAuthServiceParams } from '@care/auth';
import { useRx } from '@homepay/rx';
import { GtmHelper } from '@care/google-tag-manager';
import { FeatureFlags, FeatureFlagsProvider } from '@/components/contexts/FeatureFlagsContext';
import {
  CLIENT_FEATURE_FLAGS,
  CZEN_JSESSIONID_COOKIE_KEY,
  CZEN_VISITOR_COOKIE_KEY,
  HOMEPAY_PROMO_CODE_COOKIE,
  HOMEPAY_RX_COOKIE,
} from '@/constants';
import { initializeApollo } from '@/apollo-client';
import { Member, MEMBER } from '@/API/member';
import UserProvider, { AuthData } from '@/components/UserProvider';
import { NextConfig } from '@/interfaces';
import { fixUrlQuery, getUrlQueryParamByName } from '@/utils/urlHelper';
import createPromoCodeCookie from '@/utils/createPromoCodeCookie';
import { GtmService } from '@/utils/GtmService';
import { proximanova, proximanovabold, proximanovathin } from '../utils/proximaNovaFont';

const {
  publicRuntimeConfig: {
    AUTH_CLIENT_ID,
    AUTH_PREFIX,
    AUTH_RESPONSE_TYPE,
    AUTH_SCOPE,
    BASE_PATH,
    GOOGLE_SITE_KEY: googleSiteKey,
    OIDC_AUTHORITY,
    OIDC_POST_LOGOUT_REDIRECT_URI,
    OIDC_SERVER_CALLBACK_URL,
    RECAPTCHA_ENABLED,
  },
} = getConfig() as NextConfig;

// Add the font-face configurations for Proxima Nove to the global styles.
const proximaOverride = {
  MuiCssBaseline: {
    '@global': { '@font-face': [proximanova, proximanovabold, proximanovathin] },
  },
};
Object.assign(theme.components || {}, proximaOverride);

type HomePayAppProps = AppProps & {
  auth: AuthData | undefined;
  careDeviceId: string;
  czenJSessionId: string | undefined;
  czenVisitorId: string | undefined;
  enrollmentSessionId: string;
  err: Error;
  ldClientFlags: FeatureFlags;
  nonce?: string;
  referrerCookie: string | undefined;
};

export default function App({
  Component,
  auth,
  ldClientFlags,
  pageProps,
  enrollmentSessionId,
  czenVisitorId,
  careDeviceId,
  nonce,
  referrerCookie,
}: HomePayAppProps) {
  const [authService, setAuthService] = useState<IAuthService | undefined>();
  const [apolloClient, setApolloClient] = useState<
    ApolloClient<NormalizedCacheObject> | undefined
  >();
  const router = useRouter();
  const { query } = router;
  const cache = createCache({ key: 'emotion-cache', nonce, prepend: true });

  useRx(query, referrerCookie);

  useEffect(() => {
    const authServiceSettings: IAuthServiceParams = {
      authority: OIDC_AUTHORITY,
      clientStorage: window.localStorage,
      client_id: AUTH_CLIENT_ID,
      post_logout_redirect_uri: OIDC_POST_LOGOUT_REDIRECT_URI,
      redirect_uri: OIDC_SERVER_CALLBACK_URL,
      response_type: AUTH_RESPONSE_TYPE,
      scope: AUTH_SCOPE,
      storagePrefix: AUTH_PREFIX,
    };

    const newAuthService = AuthService(authServiceSettings);
    setAuthService(newAuthService);
    const newApolloClient = initializeApollo({ authService: newAuthService });
    setApolloClient(newApolloClient);
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-void
      void import('@axe-core/react').then(async ({ default: axe }) => {
        await axe(React, ReactDOM, 1000);
      });
    }
  });

  useEffect(() => {
    if (router.pathname !== '/authCallback') {
      GtmHelper.pushFromSessionStorageUsingGtmService(GtmService);
    }
  }, [router.pathname]); // pushFromSessionStorageUsingGtmService needs to be called on a next page load so we need to add a pathname here

  // we're using `useRef` to ensure that Sentry.setUser() is invoked just once and before any child components are rendered
  const sentryUserInitializedRef = useRef<boolean>(false);
  if (!sentryUserInitializedRef.current) {
    Sentry.setUser({
      id: careDeviceId,

      // other user identifiers that might be useful for debugging purposes
      enrollmentSessionId,
      czenVisitorId,
    });

    sentryUserInitializedRef.current = true;
  }

  if (!apolloClient) {
    return null;
  }

  return (
    <>
      {RECAPTCHA_ENABLED && (
        <Script
          strategy="lazyOnload"
          nonce={nonce}
          src={`https://www.google.com/recaptcha/enterprise.js?render=${googleSiteKey}`}
        />
      )}
      <FeatureFlagsProvider flags={ldClientFlags}>
        <ApolloProvider client={apolloClient}>
          <UserProvider auth={auth} authService={authService}>
            <CacheProvider value={cache}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Component {...pageProps} />
              </ThemeProvider>
            </CacheProvider>
          </UserProvider>
        </ApolloProvider>
      </FeatureFlagsProvider>
    </>
  );
}

/**
 * Invoked during SSR to build the map of data we need to provide to the client.  This function also serves to disable
 * static page generation in favor of SSR for *all* pages, which is currently needed in order to inject
 * the proper env-specific config at runtime rather than at build time.
 * @param appContext
 */
export async function getInitialProps(appContext: AppContext) {
  const {
    ctx: { req, res },
  } = appContext;

  // if the query string is malformed because there are multiple ?s,
  // redirect to the url with the fixed query string
  if ((req?.url?.match(/\?/g) || []).length > 1) {
    let url = req?.url;
    if (!url?.startsWith(BASE_PATH) && typeof url === 'string') {
      url = BASE_PATH.concat(url);
    }
    res?.writeHead(302, {
      Location: fixUrlQuery(url),
    });
    res?.end();
    return {};
  }

  const apolloClient = initializeApollo();
  const ldClientFlags: FeatureFlags = {};
  const props: Partial<HomePayAppProps> = { ldClientFlags };
  let careDeviceId;
  if (req) {
    const cookies = new Cookies(req.headers.cookie);

    const promoCodeParam = getUrlQueryParamByName(HOMEPAY_PROMO_CODE_COOKIE, req.url);
    if (promoCodeParam) {
      const promoCodeCookie = createPromoCodeCookie(promoCodeParam);
      res?.setHeader('Set-Cookie', promoCodeCookie);
    }

    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    // retrieves the HTTP-only cookie server-side since the client-side can't access it
    props.referrerCookie = cookies.get(HOMEPAY_RX_COOKIE);
    props.enrollmentSessionId = req?.enrollmentSessionId;

    props.czenVisitorId = cookies.get(CZEN_VISITOR_COOKIE_KEY);
    props.czenJSessionId = cookies.get(CZEN_JSESSIONID_COOKIE_KEY);
    /* eslint-enable @typescript-eslint/no-unsafe-assignment */
    const { ldClient, ldUser } = req.careContext || {};
    ({ careDeviceId } = req);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    props.careDeviceId = careDeviceId;

    if (ldClient && ldUser) {
      const clientFlagPromises = Object.values(CLIENT_FEATURE_FLAGS).map(async (flagName) => {
        ldClientFlags[flagName] = await ldClient.variationDetail(flagName, ldUser, undefined);
      });

      await Promise.all(clientFlagPromises);
    }

    props.auth = {} as AuthData;
    if (req.careContext?.session) {
      const { session } = req.careContext;
      let jwt: JwtPayload | null = null;
      if (session?.state === 'authenticated') {
        jwt = session.jwt;
        try {
          const { data } = await apolloClient.query<Member>({
            context: {
              headers: {
                Authorization: `Bearer ${session.jwtEncoded}`,
              },
            },
            query: MEMBER,
          });
          props.auth.member = data?.member;
        } catch (error) {
          Sentry.captureException(error);
        }
      }
      props.auth = {
        ...props.auth,
        isImpersonated: Boolean(jwt?.impersonated),
        isLoggedIn: Boolean(session?.state === 'authenticated'),
        memberUuid: jwt?.impersonated || jwt?.sub || '',
      };
    }
  }

  const { nonce } = req?.careContext || {};

  props.nonce = nonce;

  return props;
}

App.getInitialProps = getInitialProps;
