interface FontFaceType {
  fontDisplay?: 'swap' | 'auto' | 'block' | 'fallback' | 'optional' | undefined;
  fontFamily: string;
  fontStyle: string;
  fontWeight?: number;
  src: string;
}

export const proximanova: FontFaceType = {
  fontDisplay: 'swap',
  fontFamily: '"Proxima Nova"',
  fontStyle: 'normal',
  src: `
    url('https://www.care.com/css/cms/font-face/ProximaNova/proximanova-regular-webfont.woff2') format('woff2'),
    url('https://www.care.com/css/cms/font-face/ProximaNova/proximanova-regular-webfont.woff') format('woff');
  `,
};

export const proximanovabold: FontFaceType = {
  fontDisplay: 'swap',
  fontFamily: '"Proxima Nova"',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    url('https://www.care.com/css/cms/font-face/ProximaNova/proximanova-bold-webfont.woff2') format('woff2'),
    url('https://www.care.com/css/cms/font-face/ProximaNova/proximanova-bold-webfont.woff') format('woff');
  `,
};

export const proximanovathin: FontFaceType = {
  fontDisplay: 'swap',
  fontFamily: '"Proxima Nova"',
  fontStyle: 'normal',
  fontWeight: 100,
  src: `
    url('https://www.care.com/css/cms/font-face/ProximaNova/proximanova-thin-webfont.woff2') format('woff2'),
    url('https://www.care.com/css/cms/font-face/ProximaNova/proximanova-thin-webfont.woff') format('woff');
  `,
};
