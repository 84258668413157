import { ClientLogger } from '@care/utils';
import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';
import { NextConfig } from '@/interfaces';
import { LOGGING_PATH } from '@/constants';

const {
  publicRuntimeConfig: { BASE_PATH },
} = getConfig() as NextConfig;

const Logger = new ClientLogger({
  endpoint: `${BASE_PATH}${LOGGING_PATH}`,
  onError: Sentry.captureException,
});

export default Logger;
