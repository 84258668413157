import { useLazyQuery } from '@apollo/client';
import { IAuthService } from '@care/auth';
import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { MEMBER, Member } from '@/API/member';
import { setUserId } from '@/utils/AnalyticsHelper';

export interface AuthData {
  isImpersonated: boolean;
  isLoggedIn: boolean;
  member: Member['member'] | undefined;
  memberUuid: string;
}

export interface UserProviderData {
  auth: AuthData | undefined;
}

const initialState: UserProviderData = {
  auth: undefined,
};

export const UserContext = createContext<UserProviderData>(initialState);

interface UserProviderProps {
  auth: AuthData | undefined;
  authService: IAuthService | undefined;
  children: ReactNode;
}

const UserProvider: FC<UserProviderProps> = ({ auth, authService, children }) => {
  const [state, setState] = useState<UserProviderData>({ ...initialState, auth });
  const [fetchProfileData, { data: profileData }] = useLazyQuery<Member>(MEMBER);

  useEffect(() => {
    if (!auth?.isLoggedIn && authService?.getStore()?.access_token) {
      fetchProfileData();
    }
  }, [auth, authService, fetchProfileData]);

  const setUserProfile = (userProfile: Member['member']) => {
    setState({
      auth: {
        isImpersonated: false,
        isLoggedIn: true,
        member: userProfile,
        memberUuid: userProfile.id,
      },
    });
  };

  useEffect(() => {
    if (profileData) {
      setUserProfile(profileData.member);
      setUserId(profileData);
    }
  }, [profileData]);

  return <UserContext.Provider value={{ auth: state.auth }}>{children}</UserContext.Provider>;
};

export default UserProvider;

export const useUserAuth = () => useContext(UserContext);
