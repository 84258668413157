// A convenience function for initializing Sentry on the client and server

import getConfig from 'next/config';
import { init } from '@sentry/nextjs';
import { NextConfig } from '@/interfaces';

const { publicRuntimeConfig } = getConfig() as NextConfig;
const { SENTRY_APP_RELEASE, SENTRY_DSN, SENTRY_ENV, SENTRY_ENABLED } = publicRuntimeConfig;

const defaultConfig = {
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  release: SENTRY_APP_RELEASE,
};

export default function initSentry(config = {}) {
  if (SENTRY_ENABLED) {
    init({
      ...defaultConfig,
      ...config,
    });
  }
}
