import { LDEvaluationDetail } from 'launchdarkly-node-server-sdk';
import { createContext, ReactNode, useContext, useState } from 'react';

interface FeatureFlagsProviderProps {
  children: ReactNode;
  flags: FeatureFlags;
}

export type FeatureFlagsState = {
  flags: FeatureFlags;
  isFeatureFlagActive: (flagName: string) => boolean;
  getFeatureFlagValue: <T>(flagName: string) => T | null;
};

const initialState = {
  flags: {},
} as FeatureFlagsState;

export type FeatureFlags = Record<string, LDEvaluationDetail>;

const FeatureFlagsContext = createContext<FeatureFlagsState>(initialState);

export const FeatureFlagsProvider = ({ children, flags }: FeatureFlagsProviderProps) => {
  const [launchDarklyInfo] = useState({ flags });

  const isFeatureFlagActive = (flagName: string): boolean => {
    // no keys to compare against
    if (Object.keys(launchDarklyInfo.flags).length === 0) {
      return false;
    }

    return Boolean(launchDarklyInfo.flags[flagName].value);
  };

  const getFeatureFlagValue = <T,>(flagName: string): T | null => {
    // no keys to compare against
    if (Object.keys(launchDarklyInfo.flags).length === 0) {
      return null;
    }

    return launchDarklyInfo.flags[flagName]?.value as T;
  };

  return (
    <FeatureFlagsContext.Provider
      value={{ ...launchDarklyInfo, isFeatureFlagActive, getFeatureFlagValue }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);
