import { GtmServiceBase } from '@care/google-tag-manager';
import logger from '@/utils/clientLogger';

class GtmServiceExtended extends GtmServiceBase {}

export const GtmService = new GtmServiceExtended({
  logger,
});

// Exporting GtmEvent used when called pushDefinedEvent()
export { GtmEvent } from '@care/google-tag-manager';
export type { GtmData } from '@care/google-tag-manager';
