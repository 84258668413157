import gql from 'graphql-tag';

export enum AccountStatus {
  Client = 'CLIENT',
  None = 'NONE',
  Pending = 'PENDING',
  Submitted = 'SUBMITTED',
}

export interface Member {
  member: {
    displayName: string;
    email: string;
    firstName?: string;
    homepayAccountStatus: AccountStatus;
    id: string;
    lastName?: string;
  };
}

export const MEMBER = gql`
  query Member {
    member {
      displayName
      email
      firstName
      homepayAccountStatus
      id
      lastName
    }
  }
`;
