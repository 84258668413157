import cookie from 'cookie';
import getConfig from 'next/config';
import { HOMEPAY_PROMO_CODE_COOKIE } from '@/constants';
import { NextConfig } from '@/interfaces';

const { publicRuntimeConfig } = getConfig() as NextConfig;
const { CORE_BASE_URL } = publicRuntimeConfig;

export default (promoCode: string) => {
  const maxAge = 30 * 24 * 60 * 60; // 30 days
  const path = '/';
  const domain = CORE_BASE_URL.replace(/^https?:\/\//, '').replace('www.', '');
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + maxAge * 1000);
  return cookie.serialize(HOMEPAY_PROMO_CODE_COOKIE, promoCode, {
    domain,
    path,
    maxAge,
    expires: expirationDate,
    httpOnly: true,
  });
};
